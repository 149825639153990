<template>
  <div class="row profile-body mb-3">
    <custom-alert id="alert_box" v-if="displayAlert" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <div class="header-title" style="width: 100%">
            <div class="row">
              <div class="col-md-11">
                <h3>Staff</h3>
              </div>
              <div class="col-md-1 text-right pt-1">
                <button class="btn btn-primary btn-blue btn-sm" @click="addStaff()"><i class="fa fa-plus"></i> Add</button>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body pt-0 table-responsive">
          <b-table
              id="staff-my-table"
              :items="listData"
              :fields="tblstaffFields"
              class="mt-3"
              hover
              show-empty
          >
            <template
                :fields="items"
            >
              <div class="text-left py-0 align-middle">
                <div class="btn-group btn-group-sm">
                  <button class="btn btn-info"><i class="fas fa-eye"></i></button>
                  <button class="btn btn-primary ml-1"><i class="fas fa-pencil-alt"></i>
                  </button>
                  <button class="btn btn-danger ml-1"><i class="fas fa-trash"></i></button>
                </div>
              </div>
            </template>
            <template #empty="scope">
              <h4>{{ scope.emptyText }}</h4>
            </template>

            <template #cell(first_name)="row">
              {{row.item.personal_details.first_name}}
            </template>
            <template #cell(last_name)="row">
              {{row.item.personal_details.last_name}}
            </template>
            <template #cell(membership_name)="row">
              {{row.item.latest_registration.membership.name}}
            </template>
            <template #cell(membership_endtate)="row">
              {{formatDate(row.item.latest_registration.valid_thru)}}
            </template>
            <template #cell(member_status)="row">
              {{row.item.status_text}}
            </template>
            <template #cell(actions)="row">
              <div class="btn-group">
                <template v-if="row.item.confirmation_code !== clubuserProfileData.team_head_coach_member_id">
                  <b-button v-b-tooltip.hover title="Make as Head Coach" class="btn btn-blue btn-sm" @click="makeAsHeadCoach(row.item)">
                    <i class="fas fa-user"></i>
                  </b-button>
                </template>
                <b-button v-b-tooltip.hover title="Remove from the team" class="btn btn-danger btn-sm" @click="removeStaff(row.item)">
                  <i class="fas fa-times"></i>
                </b-button>
              </div>
            </template>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <b-modal id="addStaff" title="Add Staff" hide-footer>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="form-label" for="headCoachID"> Member ID <sup class="text-danger">*</sup></label>
            <div class="form-group input-group input-group">
              <input type="text" class="form-control" placeholder="Enter member id" maxlength="45" id="headCoachID" v-model="staffAddForm.member_id">
              <span class="input-group-append">
                  <button type="button" class="btn btn-blue btn-flat text-white" @click="staffSearch" :disabled="loader.search">
                    <i class="fa fa-circle-notch fa-spin" v-if="loader.search"></i>
                    <i class="fas fa-search mr-2" v-else></i>Search
                  </button>
                </span>
            </div>
          </div>
          <div class="form-group">
            <label class="form-label" for="headCoachName">Name</label>
            <input type="text" id="headCoachName" class="form-control" readonly v-model="staffAddForm.member_name" />
          </div>
        </div>
        <div class="col-md-12" v-if="errorMsg">
          <p class="text-danger text-center" v-html="errorMsg"></p>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <b-button class="btn btn-blue" @click="doAddStaff" :disabled="loader.add">
              <i class="fa fa-circle-notch fa-spin" v-if="loader.add"></i>
              <template v-else><i class="fa fa-plus-circle mr-2"></i>Add</template>
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import CustomAlert from "../../CustomAlert";

export default {
  components: {CustomAlert},
  props: ['listData', 'clubuserProfileData'],
  data() {
    return {
      displayAlert: false,
      alertMessage: "",
      tblstaffFields: [
        { key: 'first_name', sortable: true, label: 'First Name',sortByFormatted: true, formatter: (value, key, item) => item.personal_details.first_name  },
        { key: 'last_name', sortable: true, label: 'Last Name',sortByFormatted: true, formatter: (value, key, item) => item.personal_details.last_name  },
        { key: 'confirmation_code', sortable: true, label: 'Member #' },
        { key: 'membership_name', sortable: true, label: "Membership Type",sortByFormatted: true, formatter: (value, key, item) => item.latest_registration.membership.name },
        { key: 'membership_endtate', sortable: true, label: "Membership Expiration", sortByFormatted: true, formatter: (value, key, item) => item.latest_registration.valid_thru  },
        { key: 'member_status', sortable: true, label: 'Membership Status',sortByFormatted: true, formatter: (value, key, item) => item.status_text },
        { key: 'actions', sortable: false },
      ],
      staffAddForm: {
        member_id: null,
        member_name: null
      },
      errorMsg: null,
      memberSearchResults: [],
      loader: {
        search: false,
        add: false
      }
    }
  },
  methods: {
    resetAlert(){
      this.displayAlert = false;
    },
    formatDate(value) {
      if(value){
        return moment(value).format('MM/DD/YYYY');
      } else {
        return 'N/A';
      }
    },
    removeStaff(item){
      this.$bvModal.msgBoxConfirm('Are you sure to remove the staff from team ?', {
        title: 'Please Confirm',
        size: 'md',
        buttonSize: 'md',
        okVariant: 'danger',
        okTitleHtml: '<i class="fa fa-check mr-2"></i>Yes, Remove',
        cancelTitleHtml: '<i class="fa fa-times mr-2"></i>No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then(value => {
        if (value) {
          axios.post(this.basePath + "api/team/remove-staff", {
            member_id: item.id,
            team_id: this.$route.params.regId
          }, this.adminHeaders)
              .then(response => {
                this.alertMessage = response.data.message;
                this.displayAlert = true;
                this.$emit('refreshProfile');
              })
              .catch(err => {
                console.log(err);
              });
        }
      })
      .catch(err => {
        console.log(err);
      })
    },
    makeAsHeadCoach(item){
      this.$bvModal.msgBoxConfirm('Are you sure to make this staff as head coach ?', {
        title: 'Please Confirm',
        size: 'md',
        buttonSize: 'md',
        okVariant: 'primary',
        okTitleHtml: '<i class="fa fa-check mr-2"></i>Yes',
        cancelTitleHtml: '<i class="fa fa-times mr-2"></i>No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then(value => {
        if (value) {
          axios.post(this.basePath + "api/team/make-as-head-coach", {
            member_id: item.id,
            team_id: this.$route.params.regId
          }, this.adminHeaders)
              .then(response => {
                this.alertMessage = response.data.message;
                this.displayAlert = true;
                this.$emit('refreshProfile');
              })
              .catch(err => {
                console.log(err);
              });
        }
      })
      .catch(err => {
        console.log(err);
      })
    },
    addStaff(){
      this.$bvModal.show("addStaff");
    },
    staffSearch(){
      if (this.staffAddForm.member_id) {
        this.staffAddForm.member_name = "";
        this.errorMsg = "";
        this.memberSearchResults = [];
        this.loader.search = true;
        axios.get(this.basePath+'api/member_lookup?member_id='+this.staffAddForm.member_id)
        .then((res) => {
          if(res.data.status === 'success') {
            this.memberSearchResults = res.data.data;
            if(this.memberSearchResults[0]?.membership.id !== 5){
              this.errorMsg = 'Member is not a coach';
            } else {
              this.staffAddForm.member_name =  this.memberSearchResults[0]?.full_name;
            }
          } else {
            this.errorMsg = 'Member is not found or Invalid Member Id';
          }
          this.loader.search = false;
        })
        .catch((error) => {
          console.log(error);
          this.loader.search = false;
        });
      } else {
        this.errorMsg = 'Member ID is required for search';
      }
    },
    doAddStaff(){
      this.errorMsg = "";
      if (this.staffAddForm.member_name) {
        this.loader.add = true;
        axios.post(
            this.basePath+"api/team/add-staff",
            {member_id: this.memberSearchResults[0]?.id, team_id: this.$route.params.regId},
            this.adminHeaders
        ).then(response => {
          this.alertMessage = response.data.message;
          this.displayAlert = true;
          this.$emit('refreshProfile');
          this.$bvModal.hide("addStaff")
          this.loader.add = false;
        }).catch(err => {
          console.log(err);
          this.loader.add = false;
        });
      } else {
        this.errorMsg = 'Please search a staff before add';
      }
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === "addStaff") {
        this.staffAddForm.member_id = null;
        this.staffAddForm.member_name = null;
      }
    })
  }
}
</script>