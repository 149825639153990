<template>
  <div class="row profile-body mb-3">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <div class="header-title">
            <h3>Club Rosters</h3>
          </div>
        </div>
        <div v-if="isProfileloading==true"  class="memberdetail-section card-body pt-0 table-responsive d-flex justify-content-center">
                <div  class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
        </div>
        <div v-else class="memberdetail-section card-body pt-0 table-responsive">
                <b-overlay :show="showLoader" no-wrap></b-overlay>
                <b-table
                  id="my-table"
                  :items="rostersInfo"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :fields="visibleFields"
                  hover
                  show-empty
                  sticky-header="450px"
                  :no-border-collapse=true
                  responsive
                  thead-class="sticky-top"
                >
                  <template #head(member_status)="data">
                    {{ data.label }}
                    <a
                      href="javascript:void(0);"
                      v-if="
                        getSiteProps('clubMemberRoster.membershipStatusIcon')
                      "
                      v-b-modal.statusInfo
                      ><img
                        v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'"
                        alt="tooltipicon"
                        style="width: 20px"
                    /></a>
                  </template>
                  <template #head(background_screening)="data">
                    {{ data.label }}
                    <template
                      v-if="getSiteProps('tooltips.backgroundScreening')"
                    >
                      <div
                        class="d-inline"
                        id="bgscreen-tooltip"
                        v-b-tooltip.hover="{ customClass: 'bgscreen-tooltip' }"
                      >
                        <img
                          v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'"
                          alt="tooltipicon"
                          style="width: 20px"
                        />
                      </div>
                      <b-tooltip
                        custom-class="bgscreenTooltip"
                        target="bgscreen-tooltip"
                        class="white"
                      >
                        <p
                          class="mb-0"
                          v-html="getSiteProps('tooltips.backgroundScreening')"
                        ></p>
                      </b-tooltip>
                    </template>
                  </template>
                  <template #empty="scope">
                    <h4>{{ scope.emptyText }}</h4>
                  </template>
                  <!-- <template #head(selected)="row"> -->
                  <template>
                    <b-form-group class="text-center">
                      <b-form-checkbox
                        @click.native.stop
                        :indeterminate="indeterminate"
                        @change="toggleAll"
                      ></b-form-checkbox>
                    </b-form-group>
                  </template>
                  <template #cell(selected)="row">
                    <b-form-group>
                      <input
                        type="checkbox"
                        v-model="selected"
                        :value="row.item.id"
                        :class="'bulkActChk' + row.index"
                      />
                    </b-form-group>
                  </template>
                  <template #cell(official_rating)="row">
                    <b-link
                      :href="
                        '/club/roster/officialratings/' +
                        row.item.official_rating
                      "
                      >Rating Details</b-link
                    >
                  </template>
                  <template #cell(actions)="row">
                    <div class="text-left py-0 align-middle">
                      <div class="btn-group btn-group-sm">
                        <button
                          class="btn btn-danger ml-1"
                          @click="deleteRoster(row.item.id)"
                        >
                          <i class="fas fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </template>
                </b-table>
              </div>
              <b-modal
                id="statusInfo"
                size="xl"
                title="Membership Status Types"
                hide-footer
              >
                <div class="table-responsive">
                  <table class="table table-sm table-bordered">
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>What This Means</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Current</td>
                        <td>
                          Paid dues, Signed Waiver, Completed Safe Sport
                          training
                        </td>
                        <td>
                          All membership requirements are met and may
                          participate in all sanctioned activities.
                        </td>
                      </tr>
                      <tr>
                        <td>Expired</td>
                        <td>Dues not paid</td>
                        <td>
                          Member needs to renew as an active (insured) member to
                          be eligible to participate in sanctioned activity.
                        </td>
                      </tr>
                      <tr>
                        <td>Waiting – Need Safe Sport</td>
                        <td>
                          Paid dues, Signed Waiver but has not completed Safe
                          Sport training
                        </td>
                        <td>
                          Membership is pending successful completion of Safe
                          Sport training within 30 days of joining as a new
                          member. May still participate in sanctioned activities
                          during this period.
                        </td>
                      </tr>
                      <tr>
                        <td>Pending – Safe Sport</td>
                        <td>
                          Paid dues, Signed Waiver but has not completed Safe
                          Sport training after 30 days
                        </td>
                        <td>
                          Not eligible to participate in sanctioned events
                          (tournaments) until Safe Sport training is completed.
                          The 30-day grace period to complete Safe Sport
                          training after joining as a new member has expired.
                        </td>
                      </tr>
                      <tr>
                        <td>Pending – Signed Waiver</td>
                        <td>Paid dues but has not signed Waiver</td>
                        <td>
                          Membership is pending signed Annual Membership Waiver.
                          Not eligible to participate in sanctioned activity
                          until waiver is signed. Please go into your individual
                          membership dashboard to sign the Waiver.
                        </td>
                      </tr>
                      <tr>
                        <td>Pending – Safe Sport & Signed Waiver</td>
                        <td>
                          Paid dues but has not signed Waiver or completed Safe
                          Sport training
                        </td>
                        <td>
                          Membership is pending successful completion of Safe
                          Sport Training and signed Annual Membership Waiver.
                          Not eligible to participate until Waiver is signed.
                          Please go into your individual membership dashboard to
                          sign the Waiver. New members have 30 days to complete
                          Safe Sport training.
                        </td>
                      </tr>
                      <tr>
                        <td>Suspended</td>
                        <td>N/A</td>
                        <td>
                          Ineligible to participate in any capacity with any USA
                          Water Ski & Wake Sports club or in any USA Water Ski &
                          Wake Sports sanctioned activity.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-modal>
              <!-- <div> -->
              <!--<b-button>{{ allSelected ? 'Un-select All' : 'Select All' }}</b-button>-->
              <!-- <b-button-group>
                                                <b-dropdown right text="With selected rosters... ">
                                                    <b-dropdown-item @click="validateRosterSelection('email')">Send a message</b-dropdown-item>
                                                    <b-dropdown-item @click="validateRosterSelection('delete')">Delete</b-dropdown-item>
                                                </b-dropdown>
                                            </b-button-group> -->
              <!-- </div> -->
            </div>
          </div>
        </div>
</template>
<script>
import axios from 'axios';
export default {
  name: "ClubUserProfile",
  components: {},
  props:['regId'],
  data() {
    return {
      isProfileloading:true,
      rostersInfo: [],
      sortBy: "last_name",
      sortDesc: false,
      safesportRefreshing: false,
      tblFieldsNew: [
        // { key: 'selected', sortable: false },
        { key: "last_name", sortable: true, label: "Last Name" },
        { key: "first_name", sortable: true, label: "First Name" },
        { key: "email", sortable: true, label: "Email" },
        { key: "confirmation_code", sortable: true, label: "Member #" },
        { key: "membership_name", sortable: true, label: "Membership Type" },
        { key: "member_status", sortable: true, label: "Membership Status / Expiration " },
        {
          key: "sports_discipline",
          sortable: true,
          label: "Primary Sport Discipline",
        },
        {
          key: "safesport",
          sortable: false,
          label: "Safe Sport Training Status / Expiration",
        },
        {
          key: "background_screening",
          sortable: false,
          label: "Background Screening Status / Expiration",
        },
        // { key: 'official_rating', sortable: false, label: "Official Ratings" },
        // { key: 'actions', sortable: false },
      ],
      selected: [],
      allSelected: false,
      indeterminate: false,
      selectedRosters: [],
      bulkEmail: {
        users: [],
        subject: "",
        message: "",
      },
      subState: null,
      msgState: null,
      exportPDFProgress: false,
    };
  },

  methods: {
    loadRostersInfo() {    
      console.log("this.clubuserProfileData.id", this.regId);
      axios
        .get(this.basePath + "api/admin/club-roster/" + this.regId, {
          headers: this.adminHeaders,
        })
        .then(
          function (response) {
            const resp = response.data.data;
            this.rostersInfo = resp;
            this.showLoader = false;
            this.isProfileloading = false;
          }.bind(this)
        )
        .catch(function (error) {
          this.showLoader = false;
          console.log(error);
        });
    },
  },
  mounted() {
    this.loadRostersInfo();
  },
  computed: {
    visibleFields() {
      return this.tblFieldsNew.filter((field) => {
        if (this.subdomain == "shooting") {
          if (
            [
              "sports_discipline",
              "official_rating",
            ].indexOf(field.key) == -1
          )
            return field;
        } else {
          return field;
        }
        if (field.key == "sports_discipline" && this.subdomain == "waterski")
          return field;
      });
    },
  },
};
</script>