<template>
    <div class="hovereffect">
        <img style="width:100%;" v-bind:src="getClubCard()"
             alt="Member Card" class="img-responsive">
        <div class="overlay">
            <!-- <h2></h2>
            <p class="set1">
                <a href="#"><i class="fab fa-twitter"></i></a>
                <a href="#"><i class="fab fa-facebook"></i></a>
            </p>
            <hr>
            <hr> -->
            <p class="set2">
                <a href="#" @click="printImage()"><i class="fas fa-print"></i></a>
                <a href="#" @click="downloadCLubCard()"><i class="fas fa-download"></i></a>
            </p>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import axios from 'axios';

export default {
    name: "clubcard",
    props:["clubId"],
    data() {
        return {}
    },
    methods:{
        getClubCard(){
            return this.basePath + '/images/club/card/'+this.clubId+'.png?v='+moment().format('x');
        },
        downloadCLubCard() {
            let requestHeaders = {};
            let clubId = '';
            if(localStorage.getItem('clubToken') != null) {
              requestHeaders = this.clubHeaders;
            } else {
              requestHeaders = this.adminHeaders;
              clubId = '/'+this.clubId;
            }
            axios.get(this.basePath + 'api/club/getcard'+clubId, {headers: requestHeaders, responseType:"arraybuffer"})
                .then(response => {
                    let blob = new Blob([response.data], { type: 'application/image' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = this.clubId+'.png'
                    link.click()
                    link.remove();
                    blob = '';
                    link='';
                })
        },
        printImage(){
            /*const prtHtml = '<img src="'+this.basePath + 'images/club/card/'+this.clubId+'.png">' +
                '<br><br>' +
                '<img src="'+this.basePath + 'images/membershipcard_back-print.png">';*/
            const prtHtml = '<img src="'+this.basePath + 'images/club/card/'+this.clubId+'-full.png">';
            const WinPrint = window.open('', '', 'left=0,top=0,width=1050,height=580,toolbar=0,scrollbars=0,status=0');

            var is_chrome = Boolean(WinPrint.chrome);

            WinPrint.document.write(`<!DOCTYPE html>
                    <html>
                        <head>
                            <style>
                                img {
                                    -webkit-print-color-adjust: exact;
                                }
                            </style>
                        </head>
                        <body>
                            ${prtHtml}
                        </body>
                    </html>`);

            WinPrint.document.close();
            if (is_chrome) {
                WinPrint.onload = function() { 
                    WinPrint.focus(); 
                    WinPrint.print();
                    WinPrint.close();
                };
            }
            else {
                WinPrint.document.close(); 
                WinPrint.focus();
                WinPrint.print();
                WinPrint.close();
            }
            setTimeout(function (){WinPrint.close();},2000);
        }
    }
}
</script>

<style scoped>
.hovereffect {
    width: 100%;
    height: 100%;
    float: left;
    overflow: hidden;
    position: relative;
    text-align: center;
    cursor: default;
}

.hovereffect .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
}

.hovereffect:hover .overlay {
    background: #00000085;
}

.hovereffect img {
    display: block;
    position: relative;
}

.hovereffect h2 {
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    font-size: 17px;
    padding: 10px;
}

.hovereffect:hover h2 {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transform: translate3d(-50%,-50%,0) scale3d(0.8,0.8,1);
    transform: translate3d(-50%,-50%,0) scale3d(0.8,0.8,1);
}

.hovereffect a.info {
    display: inline-block;
    text-decoration: none;
    padding: 7px 14px;
    text-transform: uppercase;
    color: #fff;
    border: 1px solid #fff;
    margin: 50px 0 0 0;
    background-color: transparent;
}

.hovereffect a.info:hover {
    box-shadow: 0 0 5px #fff;
}

.hovereffect hr {
    width: 40%;
    opacity: 0;
    filter: alpha(opacity=0);
    border: 1px solid #FFF;
}

.hovereffect  hr:nth-child(3) {
    -webkit-transform: translate3d(-50%,-50%,0) rotate3d(0,0,1,90deg) scale3d(0,0,1);
    transform: translate3d(-50%,-50%,0) rotate3d(0,0,1,90deg) scale3d(0,0,1);
}

.hovereffect  hr:nth-child(4) {
    -webkit-transform: translate3d(-50%,-50%,0) rotate3d(0,0,1,180deg) scale3d(0,0,1);
    transform: translate3d(-50%,-50%,0) rotate3d(0,0,1, 180deg) scale3d(0,0,1);
}

.hovereffect h2, .hovereffect hr {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(-50%,-50%,0);
    transform: translate3d(-50%,-50%,0);
    -webkit-transform-origin: 50%;
    -ms-transform-origin: 50%;
    transform-origin: 50%;
    background-color: transparent;
    margin: 0px;
}

.set1, .set2 {
    left: 50%;
    position: absolute;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(-50%,-50%,0);
    transform: translate3d(-50%,-50%,0);
    -webkit-transform-origin: 50%;
    -ms-transform-origin: 50%;
    transform-origin: 50%;
    background-color: transparent;
    margin: 0px;
    padding: 0px;
}

.set1 {
    top: 40%;
}

.set2 {
    top: 60%;
}

.hovereffect p {
    width: 30%;
    text-transform: none;
    font-size: 15px;
    line-height: 2;
}

.hovereffect p a {
    color: #fff;
}

.hovereffect p a:hover,
.hovereffect p a:focus {
    /*opacity: 0.6;
    filter: alpha(opacity=60);*/
    color: #d2302d;
}

.hovereffect  a i {
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    padding: 10px;
    font-size: 20px;
}

.set1 a:first-child i {
    -webkit-transform: translate3d(-60px,-60px,0);
    transform: translate3d(-60px,-60px,0);
}

.set1 a:nth-child(2) i {
    -webkit-transform: translate3d(60px,-60px,0);
    transform: translate3d(60px,-60px,0);
}

.set2 a:first-child i {
    -webkit-transform: translate3d(-60px,60px,0);
    transform: translate3d(-60px,60px,0);
}

.set2 a:nth-child(2) i {
    -webkit-transform: translate3d(60px,60px,0);
    transform: translate3d(60px,60px,0);
}

.hovereffect:hover hr:nth-child(3) {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translate3d(-50%,-50%,0) rotate3d(0,0,1,-90deg) scale3d(1,1,1);
    transform: translate3d(-50%,-50%,0) rotate3d(0,0,1,-90deg) scale3d(1,1,1);
}

.hovereffect:hover hr:nth-child(4) {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translate3d(-50%,-50%,0) rotate3d(0,0,1,-180deg) scale3d(1,1,1);
    transform: translate3d(-50%,-50%,0) rotate3d(0,0,1,-180deg) scale3d(1,1,1);
}

.hovereffect:hover .set1 i:empty, .hovereffect:hover .set2 i:empty {
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    opacity: 1;
    filter: alpha(opacity=100);
}
</style>
