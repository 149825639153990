<template>
    <span class=" float-right ml-3">
      <span class="fa fa-edit" v-b-modal.changeExpirationDate></span>
      <b-modal id="changeExpirationDate" ref="modal" title="Change Expiration Date" centered  @show="resetExpirationDateModal"
               @hidden="resetExpirationDateModal" @ok="changeExpirationDate()">
      <b-form-group label="Expiration Date" label-for="updateExpireDate">
        <date-pick
            v-model="updateExpireDate"
            :pickTime="false"
            name="updateExpireDate"
            id="updateExpireDate"
            :format="'MM/DD/YYYY'"
            :selectableYearRange="{from: 1900, to: 2021}"
            v-mask="'##/##/####'"
            placeholder="MM/DD/YYYY"
            class="form-control"
            :parseDate="parseDatePick"
        ></date-pick>
      </b-form-group>
    </b-modal>
  </span>
</template>

<script>
import DatePick from 'vue-date-pick';
import moment from "moment";
import axios from 'axios';

export default {
  name: "changeRegExpirationDate",
  components: { DatePick },
  props: ['memberType', 'updateExpireDate', 'memId'],
  methods:{
    resetExpirationDateModal(){
      //
    },
    changeExpirationDate(){
      let dataPost = {
        'update_in':this.memberType,
        'valid_thru':moment(this.updateExpireDate, 'MM/DD/YYYY').format("YYYY-MM-DD")
      };
      this.displayAlert = false;
      axios.post(this.basePath + 'api/update/expiredate/'+this.memId, dataPost,{ headers:this.adminHeaders })
          .then(function (response) {
            this.$emit('reload', response.data);
          }.bind(this))
          .catch(function (error) {
            console.log(error);
          });
    }
  }
}
</script>

<style scoped>

</style>